import * as yup from "yup";
export const patientValidationSchema = yup.object({
  clinicId: yup
    .string()
    .required("Select your clinic name"),
  // patientName: yup
  //   .string()
  //   .required("Enter your name"),
  patientName: yup
    .string()
    .max(45,"Name mus be less than 45 characters")
    .required("Enter your name"),

  //   zipCode:yup
  // .string()
  // .matches(/^[0-9]+$/, "Please enter valid Zip code")
  // .min(5,"Zipcode should be min 5 digits")
  // .max(5,"Zipcode should be max 5 digits")
  // .required("Please enter your zip code").test({
  //   name: 'len',
  //   params: { min: 5 },
  //   message:'We do not support virtual assistance at your location at the moment',
  //   test: value => {
  //     return value ? value.startsWith(6) :false
  //   },
  // }),
  phoneNumber: yup
    .string()
    .required("Enter your phone number")
    .matches(/^[0-9]+$/, "Please enter valid phone number")
    .max(10, "Please enter valid phone number")
    .min(10, "Please enter valid phone number"),
  email: yup
    .string()
    .required("Enter your email address")
    .email("Enter valid email address"),
});



  