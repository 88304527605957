import React, { useState, useRef, useEffect } from "react";
import { io } from "socket.io-client";
import { debounce, getApi, postApi } from "./utils/HelperFunction";
import Linkify from "react-linkify";
import { API_BASE_URL, REACT_APP_IMAGE_BUCKET_ENDPOINT } from "./utils/BaseUrl";
import axios from "axios";
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import { botAllVoices } from "./utils/HelperFunction";
import { AudioRecorder, useAudioRecorder } from 'react-audio-voice-recorder';
import { ThreeDots } from 'react-loader-spinner'

  //  for adding link in text
  const renderDynamicContentWithLink = (content)=>{
    const regex = /\[([^\]]*)\]\(([^)]*)\)/g;
    let lastIndex = 0;
    const elements = [];
    
    let match;
    while ((match = regex.exec(content)) !== null) {
      const text = match[1];
      const url = match[2];
      
      // Add the text before the link
      elements.push(content.substring(lastIndex, match.index));
      
      // Add the link
      elements.push(
        <a href={url} target="_blank" key={url}>
          {text}
        </a>
      );
      
      lastIndex = regex.lastIndex;
    }
    
    // Add the remaining text
    elements.push(content.substring(lastIndex));
    
    return elements;
  };
  
  // for checking need to add link in text
  const isOnlyContentLink = (content)=>{
    const regex = /\[([^\]]*)\]\(([^)]*)\)/g;
    return regex.test(content);
  }


const MessageWithLinks = ({ message }) => {
  return (
    <>
      {isOnlyContentLink(message) ? (
        renderDynamicContentWithLink(message)
      ) : (
        <Linkify>{message}</Linkify>
      )}
    </>
  );
};

  

const ChatExtensionView = (props) => {
  const [socket, setSocket] = useState("");
  const [msg, setMsg] = useState("");
  const recorderControls = useAudioRecorder()
  const [msgList, setMsgList] = useState([]);
  const [loader, setLoader] = useState(false);
  const [typing, setTyping] = useState(false);
  const [isAlert, setIsAlert] = useState(false);
  const [clinicOnline, setClinicOnline] = useState(false);
  const [iloadering,setIloadering] = useState(false)
  const [disabled, setDisabled] = useState({message:false,field:false});
  const [recentConversation,setRecentConversation] = useState({limit:0,message:""})
  const [isvoiceConversation,setIsVoiceConversation] = useState({status:false,message:""})
  const [botMessage,setBotMessage] = useState({message:"",isAudio:false,audioBuffer:null})
  const [botvoice,setBotVoice] = useState({voice:null,pitch:1.1,rate:0.95})
  const [audioUrl,setAudioUrl] = useState(null)
  const [transcription, setTranscription] = useState({loading:false,success:false,message:""});
  const [isCoutinue,setIsCoutinue] = useState({bot:false,user:false})
  const botSpeechSynthesis = window.speechSynthesis;
  const {
    transcript,
    listening,
    resetTranscript,
    isMicrophoneAvailable,
    browserSupportsSpeechRecognition
  } = useSpeechRecognition();

  const business = props?.data?.businessData;
  const msgRef = useRef(null);
  const waveRef = useRef(null);
  const textareaRef = useRef(null)
  var adminLive = false;

  const addAudioElement = (audioBlob) => {
    // console.log("audioBlob: ", audioBlob);
    if(socket){
      // console.log("calling audioBlob");
      setAudioUrl(null);
      setTranscription({...transcription,message:"",loading:true})
      socket?.emit('audioBlob', audioBlob);
    }
  };

  // console.log("my msglist:",msgList);
  useEffect(()=>{
    if(!listening && transcript){
      postMsg()
      resetTranscript()
      setMsg("")
      botSpeechSynthesis.cancel()
    }else{
      botSpeechSynthesis.cancel()
      setMsg(transcript)
    }
    },[transcript,listening])

    useEffect(() => {
      if(transcription?.message && botMessage.audioBuffer && botMessage.isAudio){
        const blob = new Blob([botMessage.audioBuffer], { type: 'audio/mpeg' });
        setAudioUrl(URL.createObjectURL(blob));
      }else{
       setBotMessage({message:"",isAudio:false,audioBuffer:null})
      }
      return () => {
        if (audioUrl) {
          URL.revokeObjectURL(audioUrl);
        }
      };
  }, [transcription?.message,botMessage.message]);

  const handleVoiceConversation =()=>{
    if(recorderControls.isRecording){
      recorderControls.stopRecording()
    }else{
      recorderControls.startRecording()
    }
  }

  if (!browserSupportsSpeechRecognition &&!isMicrophoneAvailable) {
  }else{
  }



  const getAllChats = async () => {
    setLoader(true);
    await getApi(
      `/user/getChats?page=1&roomID=${props?.roomId}&businessId=${business?.id}`
    ).then((result) => {
      if (result.status === 201) {
        // console.log("404");
      } else {
        setMsgList(result?.data?.data);
        setTimeout(() => {
          msgRef?.current?.scrollIntoView({
            behavior: "smooth",
            block: "end",
            inline: "nearest",
          });
          handleRead();
        }, 50);
        setLoader(false);
      }
    });
  };

  const sendChatToOwner = async (lastReply,lastMessage) => {
    try {
    const sendingData = {
      id:business?.id,
      chat:[{
        message: `Welcome to ${props?.clinicName}!`,
        user: "bot",
        room: props?.roomId,
        read: false,
        containsFile: false,
        replyMessage: false,
        isClinic: true,
        timeSent: new Date(),
        botId: business?.botId,},
      {
        message: `How may I help you?`,
        user: "bot",
        room: props?.roomId,
        read: false,
        containsFile: false,
        replyMessage: false,
        isClinic: true,
        timeSent: new Date(),
        botId: business?.botId,
      },
      ...msgList,
      {
        message: lastMessage,
        user: "bot",
        room: props?.roomId,
        read: false,
        containsFile: false,
        replyMessage: false,
        isClinic: true,
        timeSent: new Date(),
        botId: business?.botId,
      }],
      patientPhone:props?.userDetails?.patientPhone,
      patientEmail:props?.userDetails?.patientEmail,
      patientZipCode:props?.userDetails?.patientZip
    }
    const res =   await axios.post(`${API_BASE_URL}/admin/sab_send_chatHistory`,sendingData)
    // console.log("chat history sending",res);
     if(res.status === 200){
      // console.log("Successfully Send Chat");
     } 
    } catch (error) {
      // console.log("chat history sending err",error);
    }

  }

  const postMsg = (message = "") => {
    setIsAlert(false);
    const obj = {
      message: message.length ? message : (transcription?.message ? transcription?.message : msg),
      user: message?.length ? "bot" : "patient",
      room: props?.roomId,
      read: false,
      containsFile: false,
      replyMessage: false,
      isClinic: message.length ? true : false,
      timeSent: new Date(),
      botId: business?.botId,
      clinicId:props?.clinicId,
      isAudio:transcription?.message ? true :false,
      voice:botAllVoices.includes(business?.botVoice?.toLowerCase()) ? business?.botVoice?.toLowerCase() : "alloy",
      isCoutinue:isCoutinue?.user,
      isShortCode:true,
    };

    if (Boolean(obj.message.replace(/\s/g, "")) || isCoutinue?.user) {
      if (socket) {
        if(!isCoutinue?.user){
          setMsgList((msgList) => {
           return (msgList || []).concat(obj);
           });
        }
        socket?.emit("server_message", obj);
        setMsg("");
        setTimeout(() => {
          msgRef?.current?.scrollIntoView({
            behavior: "smooth",
            block: "end",
            inline: "nearest",
          });
        }, 50);
        // setRecentConversation((recentConversation)=>recentConversation+1)
        setDisabled({...disabled,message:true})
        setIloadering(true)
        setIsCoutinue({bot:false,user:false})
      }
    }
  };
  useEffect(()=>{

    textareaRef?.current?.focus()
  },[disabled?.message])

  useEffect(()=>{
  if(transcription.message){
    postMsg()
  }
  },[transcription])


//    ---- sending user chat on admin email ---------
//     useEffect(()=>{
//     if (business?.botConversationLimit != 0 && business?.botConversationLimit <= recentConversation?.limit) {
//       setDisabled({ message: true, field: true })
//       setMsgList((msgList) => {
//         return (msgList || []).concat({
//           message: business?.botLastMessage || "Our team contact you shortly",
//           user: "bot",
//           room: props?.roomId,
//           read: false,
//           containsFile: false,
//           replyMessage: false,
//           isClinic: true,
//           timeSent: new Date(),
//           botId: business?.botId,
//         })
//       })
//       sendChatToOwner(recentConversation?.message,business?.botLastMessage || "Our team contact you shortly")
//     }
//   },[recentConversation])

  const handleTextAreaHeight = ()=>{
    textareaRef.current.style.height = "1px";
    textareaRef.current.style.height = (10+textareaRef.current.scrollHeight)+"px"; 
  }

  const handleChange = (msg) => {
    setMsg(msg);
    setIsVoiceConversation({...isvoiceConversation,status:false})
    resetTranscript()
    const obj = { roomId: props?.roomId, user: "patient" };
    socket.emit("typing", obj);
  };

 useEffect(()=>{
  if(props?.hasSession && props?.roomId){
    getAllChats();
  }
 },[props?.hasSession,props?.roomId])


  useEffect(() => {
    if(props?.roomId && props?.showChat){
      const socket = io(API_BASE_URL, {
        query: { patient: true, clinic: false, roomId: props?.roomId,sendChatEmail:!business?.sendChatEmail,businessId:business?.id },
      });
      setSocket(socket);
      socket.on("connect", () => {
        socket.emit("joinRoom", { roomId: props?.roomId, user: "patient" });
      });
  
      socket.on("patient_message2", (newMessage) => {
        if (newMessage.user == "bot") {
          setIloadering(false)
          setDisabled({...disabled,message:false})
          setMsgList((msgList) => { return (msgList || []).concat(newMessage)});
          setIsCoutinue((pre)=>{return {...pre,bot:newMessage?.isCoutinue}})
          setBotMessage({message:newMessage.message,isAudio:newMessage?.isAudio,audioBuffer:newMessage?.audioBuffer})
          setRecentConversation((recentConversation)=>{return {limit:recentConversation.limit+1,message:newMessage}})
        }else{
          setMsgList((msgList) => { return (msgList || [])})
        }
        if (newMessage?.message == "I am sorry. I do not have the answer for that. Let me connect you with our support team!") {
          setIsAlert(true)
        }
        setTyping(false);
        setTimeout(() => {
          msgRef?.current?.scrollIntoView({
            behavior: "smooth",
            block: "end",
            inline: "nearest",
          });
        }, 50);
      });
      socket.on('transcription', (data) => {
        if(data?.success){
          setTranscription(()=>{return {loading:false,success:true,message:data?.transcription}});
        }else{
          setTranscription(()=>{return {loading:false,success:false,message:""}});
        }
      });
  
      socket.on("WaitForAdmin", (waitMsg) => {
        setMsgList((msgList) => {
          return (msgList || [])
            .filter((item) => item.timeSent !== waitMsg.timeSent)
            .concat(waitMsg);
        });
        setTimeout(() => {
          msgRef?.current?.scrollIntoView({
            behavior: "smooth",
            block: "end",
            inline: "nearest",
          });
        }, 50);
      });
      const loader = debounce(() => {
        if (!adminLive) {
          adminLive = true;
          postMsg("Chat is now transferred to the admin");
        }
        setTyping(false);
      }, 3000);
      socket.on("isTyping", (user) => {
        if (user?.user == "clinic") {
          setIsAlert(false);
          setTyping(true);
          setTimeout(() => {
            waveRef?.current?.scrollIntoView({
              behavior: "smooth",
              block: "end",
              inline: "nearest",
            });
          }, 50);
          loader();
        }
      });
      socket.on("readRecipt", (data) => {
      });
      socket.on("roomJoined", (newMessage) => {
        if (newMessage.user == "patient") {
          socket.emit("newJoin", { roomId: props?.roomId, user: "patient" });
        }
      });
  
      socket.connect();
  
      return () => {
        let obj ={}
        if(business?.sendChatEmail){
          obj ={ roomId: props?.roomId, user: "patient",sendChatEmail:!business?.sendChatEmail}
        }else{
          obj ={ roomId: props?.roomId, user: "patient",sendChatEmail:!business?.sendChatEmail,businessId:business?.id};
        }
        // obj = { roomId: props?.roomId, user: "patient",msgList,businessId:business?.id,patient:props?.userDetails};
        socket.emit("leaveRoom", obj);
        socket.disconnect();
      };
    }

  }, [props?.showChat,props?.roomId]);

  const handleRead = () => {
    if (socket){
      socket.emit("msgRead", { roomId: props?.roomId, user: "patient" });
    }
  };


  const transferChat = async (status) => {
    const roomId = props?.roomId;
    if (status === true) {
      await postApi("/clinic/saa_disable_bot", roomId).then((res) => {
        if (res?.status === 200) {
          adminLive = true;
          setIsAlert(false);
          postMsg("Chat is now transferred to the admin");
        }
      });
    } else {
      postMsg("Chat is not transferred to the admin");
      setIsAlert(false);
    }
  };

  const handleAudioEnded = () => {
    // Set the audio URL to null when the audio ends
    setAudioUrl(null);
  };

  const handleContinue = ()=>{
    setIsCoutinue({...isCoutinue,user:true});
   }
useEffect(()=>{
  if(isCoutinue?.user){
    postMsg()
  }
},[isCoutinue.user])


useEffect(()=>{
  if(textareaRef?.current){
    textareaRef.current?.focus()
  }
},[])

  return (
    <div className={`chat-wrapper chat-wrapper-shortcode shortcode_backgroundColor`} style={{ display: `${props?.showModal ? "block" : "none"} `}}>
      <div className="chat-wrapper-inner">
        {/* <div className="chat-head">
          <div className="left-arrow d-none">
            <img src={process.env.PUBLIC_URL + "/assets/logos/left-arrow.png"} alt="logo" />
          </div>
          <div className="logo-head">
            {clinicOnline && <div className="green-circle"></div>}
            <div className="logo-cntnr">
              <span className="heloo text-capitalize">{props?.clinicName}</span>
            </div>
          </div>
        </div> */}


        <div className="chat-box">
          {props.showChat && !props?.botLoading ? (
            <div className="row">
              <div className="shortCode-scroll-div" onClick={()=>(!disabled?.message || !transcription.loading) && textareaRef.current?.focus()}>
                {loader ? (
                  <div className="spinner-border" role="status"></div>
                ) : (
                  <div className="new-chat-cntnr" >
                    <div className="d-flex">
                      <div className="col-md-10">
                        <div className="shortCode-comment-box">
                            <p className="shortCode-comment-box-msg1">Hello!</p>
                            <p className="shortCode-comment-box-msg2">How can I assist you today?</p>
                        </div>
                      </div>
                      {audioUrl && <audio autoPlay src={audioUrl} onEnded={handleAudioEnded}/>}
                    </div>
                    {msgList?.length
                      ? msgList.map((obj, i, s) => {
                        return !obj?.isClinic ? (
                          <div key={i} className="d-flex justify-content-end">
                            <div className="shortCode-comment-box">
                              <p>
                                <Linkify>{obj?.message}</Linkify>
                              </p>
                            </div>
                          </div>
                        ) : (
                          <>
                            <div key={i} className="d-flex">
                              <div className="col-md-10">
                                <div className="shortCode-comment-box-new">
                                  <p>
                                  {/* {isOnlyContentLink(obj?.message) ? renderDynamicContentWithLink(obj?.message) : <Linkify>{obj?.message}</Linkify> } */}
                                  {/* {<Linkify>{obj?.message}</Linkify> } */}
                                  <MessageWithLinks message={obj?.message} />
                                    {/* <Linkify>{obj?.message} </Linkify> */}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </>
                        );
                      })
                      : ""}
                     { isCoutinue?.bot && <div onClick={handleContinue} className="d-flex justify-content-end">
                     <div className="shortCode-continue">
                      Continue
                      </div>
                      </div>}
                
                      {iloadering ? (<>
                        {/* <div className=" " role="status"> <img src="https://api.yekaterin.com/chat/assets/landing/dot-loader.gif" alt="searching" width={70} height={40} /></div> */}
                        <ThreeDots
                          visible={true}
                          height="50"
                          width="50"
                          color="rgba(63,94,251,1)"
                          radius="5"
                          ariaLabel="three-dots-loading"
                          wrapperStyle={{}}
                          wrapperClass=""
                        />
                      </>
                      ) : ""}
                    {/* {isAlert && (
                      <div className="d-flex">
                        <div className="col-2 col-md-2 pad-right-zero">
                          <div className="avtar-image">
                            {business?.logo ? (
                              <img
                                src={`${REACT_APP_IMAGE_BUCKET_ENDPOINT}/${business?.logo}`}
                                className="img-fluid"
                                alt="logo"
                              />
                            ) : (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="32"
                                height="32"
                                className="d-inline me-2"
                                viewBox="0 0 118 94"
                                role="img"
                              >
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M24.509 0c-6.733 0-11.715 5.893-11.492 12.284.214 6.14-.064 14.092-2.066 20.577C8.943 39.365 5.547 43.485 0 44.014v5.972c5.547.529 8.943 4.649 10.951 11.153 2.002 6.485 2.28 14.437 2.066 20.577C12.794 88.106 17.776 94 24.51 94H93.5c6.733 0 11.714-5.893 11.491-12.284-.214-6.14.064-14.092 2.066-20.577 2.009-6.504 5.396-10.624 10.943-11.153v-5.972c-5.547-.529-8.934-4.649-10.943-11.153-2.002-6.484-2.28-14.437-2.066-20.577C105.214 5.894 100.233 0 93.5 0H24.508zM80 57.863C80 66.663 73.436 72 62.543 72H44a2 2 0 01-2-2V24a2 2 0 012-2h18.437c9.083 0 15.044 4.92 15.044 12.474 0 5.302-4.01 10.049-9.119 10.88v.277C75.317 46.394 80 51.21 80 57.863zM60.521 28.34H49.948v14.934h8.905c6.884 0 10.68-2.772 10.68-7.727 0-4.643-3.264-7.207-9.012-7.207zM49.948 49.2v16.458H60.91c7.167 0 10.964-2.876 10.964-8.281 0-5.406-3.903-8.178-11.425-8.178H49.948z"
                                  fill="#1DC589"
                                ></path>
                              </svg>
                            )}
                          </div>
                        </div>

                        <div className="col-sm-10 row">
                          <div className="comment-box-new">
                            <p>Do you wanna transfer this chat to admin?</p>
                            <div className="d-flex align-items-center my-1">
                              <button
                                onClick={() => transferChat(true)}
                                id="kt_sign_in_submit"
                                className="btn common_button btn-sm fs-13 px-2 d-flex align-items-center "
                                style={{ border: "none" }}
                              >
                                Yes
                              </button>
                              <button
                                onClick={() => transferChat(false)}
                                id="kt_sign_in_submit"
                                className="btn common_button btn-sm fs-13 px-2 d-flex align-items-center ms-2"
                                style={{ border: "none" }}
                              >
                                No
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    )} */}
                    {typing && (
                      <div ref={waveRef} className="comment-box-new">
                        <div id="wave">
                          <span className="dot"></span>
                          <span className="dot"></span>
                          <span className="dot"></span>
                        </div>
                      </div>
                    )}
                    <div ref={msgRef} onLoad={() => alert("hi")}></div>
                  </div>
                )}
              </div>
               {!disabled?.field && <div className="pt-4 d-flex gap-3 align-items-center justify-content-center shortcode_backgroundColor">
                  <textarea
                  onChange={(e) => (!disabled?.message || !transcription.loading) &&  handleChange(e.target.value, e)}
                  onKeyDown={(e) => {
                    if (e.keyCode == 13) {
                      (!disabled?.message || !transcription.loading) && postMsg();
                    }
                  }}
                  onKeyUp={handleTextAreaHeight}
                  onFocus={handleRead}
                  value={msg}
                  // rows={3}
                  ref={textareaRef}
                  disabled={disabled?.message || transcription.loading}
                  placeholder="Type a message"
                  className="w-100 shortCode-input ps-2 border-0 shortcode_backgroundColor"
                  style={{maxHeight:'60px',height:'43px'}}
                  
                />
                 {business?.isVoiceChat && <button className="d-hotline btn animated zoomIn faster" style={{backgroundColor:'rgb(63,94,251)'}}
                  disabled={disabled?.message || transcription.loading}
                  onTouchStart={()=>handleVoiceConversation()}
                  onMouseDown={()=>handleVoiceConversation()}
                  onTouchEnd={()=>handleVoiceConversation()}
                  onMouseUp={()=>handleVoiceConversation()}
                
                >{recorderControls.isRecording  ?  <i className={`${transcription?.loading ? "spinner-border spinner-border-sm" :"bi bi-mic"}`}></i> : <i className={`${transcription?.loading ? "spinner-border spinner-border-sm" : "bi bi-mic-mute"}`}></i>}</button> }
                {/* <span >
                  {msg?.length ?
                  <span onClick={() => (!disabled?.message || !transcription?.loading) && postMsg()} className={`${(!disabled?.message || !transcription?.loading) && "cursor-pointer"}`} >
                    <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                      width="22" height="23" viewBox="0 0 92.000000 96.000000"
                      preserveAspectRatio="xMidYMid meet">

                      <g transform="translate(0.000000,96.000000) scale(0.100000,-0.100000)"
                        fill="#000000" stroke="none">
                        <path d="M452 797 c-562 -189 -541 -165 -253 -287 l199 -85 89 -210 c50 -116
                96 -211 104 -213 10 -2 56 123 168 460 85 255 156 469 159 476 2 6 0 12 -4 11
                -5 0 -213 -69 -462 -152z m273 -298 c-69 -206 -128 -381 -131 -389 -3 -9 -37
                59 -80 160 -41 96 -79 179 -84 185 -4 5 -87 44 -184 85 -96 40 -174 76 -172
                77 5 5 770 261 773 258 2 -1 -54 -170 -122 -376z"/>
                      </g>
                    </svg>
                    </span>
                    : <svg xmlns="http://www.w3.org/2000/svg" width="22" height="23" viewBox="0 0 22 23">
                      <path fill="none" fill-rule="evenodd" stroke="#666" d="M21.213 1.071l-7.07 21.213-4.209-9.865L0 8.142z" opacity=".5" />
                    </svg>}
                </span> */}
              </div>}
              <div style={{display:'none'}}>
                <AudioRecorder 
                onRecordingComplete={(blob) => addAudioElement(blob)}
                recorderControls={recorderControls}
                />
                </div>
              {/* <div className="free-chat mt-2">
                <span>
                  24/7{" "}
                  <a style={{ pointerEvents: "none" }} href="#">
                    support
                  </a>{" "}
                  for you
                </span>
              </div> */}
            </div>
          ) :<>
              {props?.botLoading && <div className="chat-bot"><div className="row">
                <div style={{height:500}} className="d-flex gap-2  align-items-center justify-content-center">
                <div class="spinner-grow spinner-grow-sm bg-purple" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
                <div class="spinner-grow spinner-grow-sm bg-purple" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
                <div class="spinner-grow spinner-grow-sm bg-purple" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
          </div>
                </div></div> }
          </>}
          <div className="free-chat">
                <span>
                Powered by{" "}
                  <a href="https://yekaterin.com/" target="_blank">
                 Yekaterin
                  </a>{" "}
                 
                </span>
              </div>
        </div>
      </div>
    </div>
  );
};

export default ChatExtensionView;
