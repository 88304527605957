import './App.css';
import ChatModal from './components/Modal';

function App() {
  return (
    <div className='tushar main-container'>
      <ChatModal />
    </div>
  );
}

export default App;
