import axios from "axios";
import { API_BASE_URL } from "./BaseUrl";

const patientChat = "patient_chat"
const userSession = "user_session"
const extensionSession = "extension_session"

export const setChatPatient = (data) => {
    localStorage.setItem(patientChat, data);
  };
  export const getChatPatient = () => {
    const user = localStorage.getItem(patientChat);
    return user;
  };

  export const setUserSession = (data) => {
    sessionStorage.setItem(userSession, data);
  };
  export const getUserSession = () => {
    const user = sessionStorage.getItem(userSession);
    return user;
  };


  export const getExtensionSession =()=>{
    const user = sessionStorage.getItem(extensionSession);
    return user;
  }

  export const setExtensionSession =(data)=>{
    sessionStorage.setItem(extensionSession, data);
  }


  export const debounce = function(fn, d) {
    let timer;
    
    return function() {
      if (timer) {
        clearTimeout(timer);
      }
  
      timer = setTimeout(fn, d);
    };
  };
  export const getApi = (path, data) => {
    return axios({
        method: 'get',
        url: API_BASE_URL+ path,
        params:{data:data}
    })
  }
  export const postApi = (path, data) => {
    return axios({
        method: 'post',
        headers: { 'content-type': 'application/json'},
        url: API_BASE_URL + path,
        data: {data:data}
    })
  }

  export const botAllVoices =["alloy", "echo", "fable", "onyx", "nova","shimmer"]