import axios from "axios";
import { API_BASE_URL } from "../components/utils/BaseUrl";

export const addPatient=(formData)=>{
    return axios.post(
      `${API_BASE_URL}/user/api/patientCheck`,
      {formData}
    );
  }


export const addExtenstionUser=(clinicId)=>{
    return axios.post(
      `${API_BASE_URL}/user/api/addPatient?clinicId=${clinicId}`,
    );
  }

export const getBotExtension=(key)=>{
    return axios.get(`${API_BASE_URL}/clinic/saa_bot_extension?chatApiKey=${key}`);
  }

